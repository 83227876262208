@font-face {
    font-family: 'Mona Sans';
    src:
      url('../font/Mona-Sans.woff2') format('woff2 supports variations'),
      url('../font/Mona-Sans.woff2') format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
  }
  
  html {
    font-family: 'Mona Sans';
  }
  